import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";

import * as level from "models/setup/levels";

const AddEditLevel = props => {
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    props.setSelected(null)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      levelTypeId: props.levelTypeId,
      levelName: form.levelName.value
    }
    if (props.toEdit) {
      level.updateLevel(payload, authToken, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.response?.data?.message)
        })
    } else {
      level.addLeveL(payload, authToken)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  return (
    <React.Fragment>
      <Modal show={props.showModal}
        centered
        onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Level 
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="levelName">
              <Form.Label>
                Level Name
              </Form.Label>
              <Form.Control
                placeholder="e.g Grade 1"
                defaultValue={props.selected?.levelName}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="levelTypeName">
              <Form.Label>
                Level Type Name
              </Form.Label>
              <Form.Control
                placeholder="e.g Primary"
                value={props.levelType}
                required
              />
            </Form.Group>

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditLevel.propTypes = {}

export default AddEditLevel