import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'

import * as role from 'models/setup/roles'

import TableTemplate from 'components/TableTemplate'
import ExpandableRowComponent from './ExpandableComponentRole'
import AddEditRole from './AddEditRole'
import DeleteRole from './DeleteRole'

const Roles = props => {
    const[roles,setRoles] = useState([])
    const[isLoaded,setIsLoaded] = useState(false)

    
    const[showAddEditRole,setShowAddEditRole] = useState(false)
    const[showDeleteRole,setShowDeleteRole] = useState(false)

    const[selected,setSelected] = useState(null)
    const[toEdit,setToEdit] = useState(false)

    const columns = [
        {
            name: "Actions",
            cell: (row) => {
                return (
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-sm text-info text-nowrap" onClick={() => {
                            setShowAddEditRole(true)
                            setSelected(row)
                            setToEdit(true)
                        }}>
                            Edit
                        </button>
                        <button className="btn btn-sm text-danger text-nowrap" onClick={() => {
                            setShowDeleteRole(true)
                            setSelected(row)
                        }}>
                            Delete
                        </button>
                    </div>
                )
            }
        },
        { name: "Role Name", selector: row => row?.name },
    ]

    const getRoles = () => {
        const token= JSON.parse(localStorage.getItem('Token'))
        const authToken=token?.authToken?.token

        role.getRoles(authToken)
        .then(resp => {
            setRoles(resp.results)
            setIsLoaded(true)
        })
        .catch(err => {
            setIsLoaded(false)
        })
    }

useEffect(() => {   
    getRoles()
},[])

  return (
    <div className='page-content'>
    <Card>
    <Card.Header>
        <Card.Title className='text-primary'>
            Roles
        </Card.Title>
    </Card.Header>
    <Card.Body>
        <div className="d-flex justify-content-end">
            <button className="btn btn-primary" onClick={() => setShowAddEditRole(true)}>
                Add Role
            </button>
        </div>
        <div className="mt-3">
            <TableTemplate
                columns={columns}
                rows={roles}
                dataLoaded={isLoaded}
                expandableRows={true}
                expandableRowsComponent={ExpandableRowComponent}
            />
        </div>

        {/* modals */}

        <AddEditRole
          showModal={showAddEditRole}
          setShowModal={setShowAddEditRole}
          toEdit={toEdit}
          setToEdit={setToEdit}
          selected={selected}
          setSelected={setSelected}
          fetchData={getRoles}/>

        <DeleteRole
          showModal={showDeleteRole}
          setShowModal={setShowDeleteRole}
          selected={selected}
          setSelected={setSelected}
          fetchData={getRoles}
        />

    </Card.Body>
    </Card>
    </div>
  )
}

Roles.propTypes = {}

export default Roles