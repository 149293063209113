import React, { Fragment, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

import DataTable, { createTheme } from "react-data-table-component"
import tableStyle from "./tableStyle"

import { Col, Form, InputGroup, Spinner } from "react-bootstrap"


const TableTemplate = props => {

  const [searchText, setSearchText] = useState("")
  const [listData, setListData] = useState([])

  const onFilter = e => {
    const searchValue = e.target.value
    setSearchText(searchValue)

    setListData(
      props.rows.filter(data => {
        return props.columns.some(header => {
          return (
            (header.filterable === undefined || header.filterable) &&
            data.hasOwnProperty(header.uniq) &&
            data[header.uniq] &&
            data[header.uniq]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
        })
      })
    )
  }

  const handleRowSelected = useCallback(state => {
    props.setSelectedRows(state.selectedRows);
  }, []);

  useEffect(() => {
    setListData(props.rows)
  }, [props])

  useEffect(() => {
    if (searchText.length < 1) {
      setListData(props.rows)
    }
  }, [searchText])

  createTheme(
    "solarized",
    {
      background: {
        default: "transparent",
      },
      divider: {
        default: "#00000009",
      },
    },
    "light"
  )

  const subHeader = (
    <div className="d-flex py-2 px-2 m-3  flex-column flex-md-row justify-content-md-between" style={{ background: "#F8F8F8" }}>
      {props?.subHeaderNav}
      {/* <InputGroup className="rounded" style={{ width: 200 }}>
        <span className="fw-bold input-group-text bg-white border-0 pt-2">
          <i className="fa fa-search" />
        </span>
        <Form.Control
          value={searchText}
          onChange={onFilter}
          placeholder="Type to search..."
          className="border-0 shadow-none rounded"
        />
        <button
          type="button"
          onClick={() => setSearchText("")}
          className={`btn bg-transparent text-secondary fw-bold py-1 rounded ${searchText.length > 0 ? "" : "d-none"
            }`}
        >
          X
        </button>
      </InputGroup> */}


    </div>
  )

  return (
    <Fragment>

      {subHeader}

      <DataTable
        columns={props.columns}
        data={listData}
        striped
        dense
        responsive
        expandableRows={props.expandableRows}
        expandableRowsComponent={props?.expandableRowsComponent}
        highlightOnHover
        progressPending={!props.dataLoaded}
        progressComponent={
          <div className="py-3 text-center">
            <Spinner
              animation="border"
              role="status"
              variant="primary"
              className=""
            />
            <h2 className="text-primary">Loading...</h2>
          </div>
        }
        fixedHeader
        fixedHeaderScrollHeight="450px"
        subHeader
        subHeaderComponent={<div className="w-100">
          {props?.contextActions}
        </div>}
        // pagination
        pagination
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: "View All",
        }}
        paginationIconFirstPage={<span className="fw-bold">First</span>}
        paginationIconNext={<span className="fw-bold">Next</span>}
        paginationIconLastPage={<span className="fw-bold">Last</span>}
        paginationIconPrevious={<span className="fw-bold">Prev</span>}
        paginationPerPage={10}
        // selectableRows
        selectableRows={props?.showSelectedRows || false}
        onSelectedRowsChange={handleRowSelected}
        contextActions={props?.contextActions}
        // style
        theme="solarized"
        customStyles={tableStyle}
      />
    </Fragment>
  )
}

TableTemplate.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  dataLoaded: PropTypes.bool,
  subHeaderNav: PropTypes.any,
  showSelectedRows: PropTypes.bool,
  selectedRows: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  expandableRows: PropTypes.bool,
  expandableRowsComponent: PropTypes.any,
  contextActions: PropTypes.any
}

export default TableTemplate
