import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Container, Row, Col } from "react-bootstrap"
import moment from "moment"
// Toast Alert
import toast from "react-hot-toast"
import * as auth from "models/auth";
import Swal from "sweetalert2";

import avatar from "assets/images/users/person-thumbnail.png"


const UserProfile = props => {
  const [vehicleProfile, setVehicleProfile] = useState([])
  const [proposalProfile, setProposalProfile] = useState([])
  const [proposalAttachments, setProposalAtttachments] = useState([])
  const [userProfile, setUserProfile] = useState({})

  const [isLoaded, setIsLoaded] = useState(false)


  const {
    match: { params },
  } = props

  const getUserProfile = () => {
    const profile = JSON.parse(localStorage.getItem("authUser"))
    const token = profile?.token
    setIsLoaded(false)
    auth.getUserById(params?.id, token)
      .then(resp => {
        const data = resp.profile
        setUserProfile(data)
        setIsLoaded(true)
      }).catch(err => {
        let timerInterval;
        if (err?.response?.data?.message === "Unauthorized") {
          Swal.fire({
            title: "Unauthorized!",
            html: "Redirecting you to Login",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("");
              timerInterval = setInterval(() => {
                // timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
            }
          });
        }

        toast.error(err?.response?.data?.message)
      })
  }


  useEffect(() => {
    getUserProfile()

  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft m-0 text-primary py-4 px-3">
            <h4 className="text-primary">{userProfile?.userType === "super-admin" ? "Super Admin" : userProfile?.userType === "admin" ? "Admin" : userProfile?.userType === "content-creator" ? "Content Creator" : userProfile?.userType === "content-evaluator" ? "Content Evaluator" : userProfile?.userType === "sales-person" ? "Sales Person" : userProfile?.userType === "publisher" ? "Publisher" : userProfile?.userType === "examiner" ? "Examiner" : userProfile?.userType === "instructor" ? "Instructor" : userProfile?.userType === "marketer" ? "Marketer" : userProfile?.userType === "student" ? "Student" : null} Profile</h4>
            <p>Current Information{ }</p>
          </div>

          <Card.Body className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light" style={{ marginTop: '-2rem' }}>
                    <img
                      src={avatar}
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  </span>
                </div>
                <p className="text-muted mb-0"> Name</p>
                <h5 className="font-size-15 text-truncate">{userProfile?.fullName}</h5>

              </Col>

              <Col sm="8" className="pt-4">
                <Row>
                  <Col xs="4">
                    <p className="text-muted mb-0">Email Address</p>
                    <h5 className="font-size-15">{userProfile?.userEmail}</h5>
                    {/* <p className="text-muted mb-0">Business Name</p>
                    <h5 className="font-size-15">{ }</h5>
                    <p className="text-muted mb-0">Business Phone</p>
                    <h5 className="font-size-15">{ }</h5> */}
                  </Col>
                  <Col xs="4">
                    <p className="text-muted mb-0">Phone Number</p>
                    {/* <p className="text-muted mb-0 text-truncate">{underwriters[params?.id - 1]?.phoneNumber}</p> */}
                    <h5 className="font-size-15">{userProfile?.phoneNumber}</h5>
                    {/* <p className="text-muted mb-0">Business Type</p> */}
                    <h5 className="font-size-15 capitalize">{ }</h5>
                    {/* <p className="text-muted mb-0"> Type</p>
                    <h5 className="font-size-15 capitalize">{type === false ? "Corporate" : "Individual"}</h5> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>






      </Container>
    </div>
  )
}

UserProfile.propTypes = {}

export default UserProfile