import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

import * as leveltype from "models/setup/leveltypes";

import CreateExamModal from "./CreateExamModal";

const CreateQuestionInterface = (props) => {
  const [questions, setQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [questionType, setQuestionType] = useState("mcq");
  const [questionText, setQuestionText] = useState("");
  const [options, setOptions] = useState([""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [studentAnswers, setStudentAnswers] = useState({});
  const [score, setScore] = useState(null);

  const [showExamModal, setShowExamModal] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [levelTypes, setLevelTypes] = useState([]);

  const {
    match: { params },
  } = props;

  const handleAddQuestion = () => {
    const newQuestion = {
      id: questions.length + 1,
      type: questionType,
      question: questionText,
      options:
        questionType === "mcq" || questionType === "checkbox" ? options : null,
    };

    const newCorrectAnswer = {
      questionId: newQuestion.id,
      answer: questionType === "checkbox" ? correctAnswer.split(",") : correctAnswer,
    };

    console.log("correctAnswers", correctAnswers)

    setQuestions([...questions, newQuestion]);
    setCorrectAnswers([...correctAnswers, newCorrectAnswer]);

    // Reset fields for the next question
    setQuestionText("");
    setOptions([""]);
    setCorrectAnswer("");
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOptionField = () => {
    setOptions([...options, ""]);
  };

  const renderOptionsField = () => (
    <div>
      <p>Options:</p>
      {options.map((option, index) => (
        <input
          key={index}
          type="text"
          value={option}
          onChange={(e) => handleOptionChange(index, e.target.value)}
          className="option-input"
          placeholder={`Option ${index + 1}`}
        />
      ))}
      <button type="button" onClick={addOptionField} className="add-option-btn">
        + Add Option
      </button>
    </div>
  );

  const handleTypeChange = (e) => {
    setQuestionType(e.target.value);
    setOptions([""]);
  };

  const gradeExam = () => {
    let score = 0;
    questions.forEach((question) => {
      const studentAnswer = studentAnswers[question.id];
      const correctAnswer = correctAnswers.find(
        (a) => a.questionId === question.id
      )?.answer;

      if (question.type === "checkbox") {
        if (
          JSON.stringify(studentAnswer?.sort()) === JSON.stringify(correctAnswer?.sort())
        ) {
          score += 1;
        }
      } else if (studentAnswer === correctAnswer) {
        score += 1;
      }
    });
    setScore(score);
  };

  const getAllLevelTypes = () => {
    leveltype
      .getAllLevelTypes()
      .then((resp) => {
        const data = resp.results;
        setLevelTypes(data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllLevelTypes();
  }, []);

  return (
    <div className="bg-white page-content">
      <Container fluid>
        <Row>
          <Col md={6}>
            <h2>Create a New Question</h2>
            <div className="form-group">
              <label>Question Type:</label>
              <select
                value={questionType}
                onChange={handleTypeChange}
                className="question-type-select"
              >
                <option value="mcq">Multiple Choice (MCQ)</option>
                <option value="truefalse">True/False</option>
                <option value="shortanswer">Short Answer</option>
                <option value="essay">Essay</option>
                <option value="checkbox">Checkbox (Multiple Select)</option>
              </select>
            </div>

            <div className="form-group">
              <label>Question Text:</label>
              <textarea
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                className="question-textarea"
                placeholder="Enter the question here"
              />
            </div>

            {(questionType === "mcq" || questionType === "checkbox") &&
              renderOptionsField()}

            <div className="form-group">
              <label>Correct Answer:</label>
              <input
                type="text"
                value={correctAnswer}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="correct-answer-input"
                placeholder="Enter correct answer (for checkboxes, separate by commas)"
              />
            </div>

            <button
              type="button"
              onClick={handleAddQuestion}
              className="add-question-btn"
            >
              Add Question
            </button>
          </Col>
          <Col md={6} className="overflow-auto">
            <div className="create-question-containerd">
              <h3>Questions Preview</h3>
              {questions.length > 0 ? (
                <ul className="questions-list">
                  {questions.map((question) => {
                    const answer = correctAnswers.find(
                      (a) => a.questionId === question.id
                    )?.answer;
                    return (
                      <li key={question.id} className="question-preview">
                        <strong>
                          Q{question.id} ({question.type}):
                        </strong>{" "}
                        {question.question}
                        {question.options && (
                          <ul>
                            {question.options.map((option, index) => (
                              <li key={index}>{option}</li>
                            ))}
                          </ul>
                        )}
                        {answer && (
                          <p>
                            <strong>Correct Answer:</strong>{" "}
                            {Array.isArray(answer) ? answer.join(", ") : answer}
                          </p>
                        )}
                      </li>
                    );
                  })}
                  <button
                    className="btn-primary btn my-2"
                    onClick={() => {
                      setShowExamModal(true);
                    }}
                  >
                    Submit
                  </button>
                </ul>
              ) : (
                <p>No questions added yet.</p>
              )}
            </div>
          </Col>
          <CreateExamModal
            showModal={showExamModal}
            setShowModal={setShowExamModal}
            toEdit={toEdit}
            setToEdit={setToEdit}
            selected={selected}
            setSelected={setSelected}
            levelTypes={levelTypes}
            type={params?.type}
            questions={questions}
            correctAnswers={correctAnswers}
          />
        </Row>
      </Container>
    </div>
  );
};

CreateQuestionInterface.propTypes = {};

export default CreateQuestionInterface;
