import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

export const addRole = async (token,payload) => {
  return await axios
    .post(getApi.ROLES.ADD_ROLE, payload,{
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};




export const getRoles = async (token,params) => {
  return await axios
    .get(`${getApi.ROLES.GET_ROLES}${params === undefined ? "" : params}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getPermissions = async (token,params) => {
  return await axios
    .get(`${getApi.ROLES.GET_PERMISSIONS}${params === undefined ? "" : params}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//put Functions

export const updateRole = async (token, payload,roleId) => {
  return await axios
    .patch(`${getApi.ROLES.UPDATE_ROLE}/${roleId}`, payload,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};


//Delete Funtions

export const deleteRole = async (token,roleId) => {
  return await axios
    .delete(`${getApi.ROLES.DELETE_ROLE}/${roleId}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}
