import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


export const addSubTopic = async (payload, token) => {
    return await axios
      .post(getApi.SETUP.ADD_SUB_TOPIC, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err))
  }
  
  
  export const getAllSubTopics = async (params) => {
    return await axios
      .get(`${getApi.SETUP.GET_ALL_SUB_TOPICS}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const getSubTopicById = async (id) => {
    return await axios
      .get(`${getApi.SETUP.GET__SUB_TOPIC_BY_ID}/${id}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  
  export const updateSubTopic = async (payload, token, topicId) => {
    return await axios
      .patch(`${getApi.SETUP.UPDATE_SUB_TOPIC}/${topicId}`, payload, {
  
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };

  export const deleteSubTopic = async (token, subTopicId) => {
    return await axios
      .delete(`${getApi.SETUP.DELETE_SUB_TOPIC}/${subTopicId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data
      })
      .catch(err => catchError("", err))
  }
  
  

  