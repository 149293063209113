import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import toast from "react-hot-toast";

import * as upload from 'models/uploads';

const DeleteContent = (props) => {
  const token= JSON.parse(localStorage.getItem('Token'))
  const authToken=token?.authToken?.token

  const deleteContent = () => {
    upload.deleteContent(authToken, props.selected?._id)
      .then((resp) => {
        Swal.fire({
          title: 'Deleted!',
          text: 'The Content has been successfully deleted.',
          icon: 'success',
        });

        // Perform additional actions like closing the modal
        props.setShowModal(false);
        props.setSelected(null);
        props.fetchData()
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthorized") {
          let timerInterval;
          Swal.fire({
            title: "Unauthorized!",
            html: "Redirecting you to Login in <b></b> seconds.",
            timer: 5000, // Timer in milliseconds
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                const secondsLeft = Math.ceil(Swal.getTimerLeft() / 1000); // Convert ms to seconds
                timer.textContent = `${secondsLeft}`;
              }, 100); // Update every 100ms for smooth updates
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              localStorage.removeItem('authUser');
              window.location.href = "/login";
            }
          });

        } else {
          toast.error(err?.response?.data?.message)
          Swal.fire({
            title: 'Error!',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
          });
        }

      })
    props.setShowModal(false);
    props.fetchData()
  };

  useEffect(() => {
    if (props.showModal) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          deleteContent(); // Call the delete function after confirmation
        } else {
          props.setShowModal(false); // Close modal if the user cancels
        }
      });
    }
  }, [props.showModal]);

  return null; // This component doesn't render any JSX
};

DeleteContent.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

export default DeleteContent;
