import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import Select from "react-select";
import Swal from 'sweetalert2';

import toast from "react-hot-toast";


import * as upload from "models/uploads";
import * as topic from "models/setup/topics";
import * as subtopic from "models/setup/subtopics"

const AddEditContent = props => {
  const [isLoading, setIsLoading] = useState(false)

  const [topics, setTopics] = useState([])
  const [subTopics, setSubTopics] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [counties, setCounties] = useState([])
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [listtedSubject, setListedSubjects] = useState([])
  const [selectedFileType, setSelectedFileType] = useState({ name: "pdf", value: "pdf" })
  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedSubTopic, setSelectedSubTopic] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)


  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    setSelectedFileType({ name: "pdf", value: "pdf" })
    setSelectedFile(null)
    props.setSelected(null)
  }


  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSelectSubject = (selectedSubject) => {
    setSelectedSubject(selectedSubject)
  }
  const handleSelectTopic = (selectedTopic) => {
    setSelectedTopic(selectedTopic)
  }
  const handleSelectSubTopic = (selectedSubTopic) => {
    setSelectedSubTopic(selectedSubTopic)
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    console.log(file)
    setSelectedFile(file)
  }

  const uploadFile = (form, token) => {
    setIsLoading(true)
    // const payload = {
    //   // id: uuid.v4(),
    //   topicName: form.topicName.value,
    //   sessionName: form.sessionName.value,
    //   level: selectedLevel.value,
    //   levelType: selectedLevelType.value
    // }
    const formData = new FormData()

    //formData.append('subtopic', selectedTopic.value);
    // formData.append('contentName', form.contentName.value);
    // formData.append('level', selectedLevel.value);
    // formData.append('sessionName', selectedSession.value);
    formData.append('fileType', selectedFileType?.value);
    // formData.append('levelType', selectedLevelType.value);

    formData.append("file", selectedFile)
    upload.uploadContent(formData, selectedSubTopic?.value, authToken)
      .then(resp => {
        setIsLoading(false)
        closeModal()
      })
      .catch(err => {
        setIsLoading(false)
        if (err?.response?.data?.message === "Unauthorized") {
          let timerInterval;
          Swal.fire({
            title: "Unauthorized!",
            html: "Redirecting you to Login in <b></b> seconds.",
            timer: 5000, // Timer in milliseconds
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                const secondsLeft = Math.ceil(Swal.getTimerLeft() / 1000); // Convert ms to seconds
                timer.textContent = `${secondsLeft}`;
              }, 100); // Update every 100ms for smooth updates
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              localStorage.removeItem('teutiUser');
              window.location.href = "/login";
            }
          });

        } else {
          toast.error(err?.response?.data?.message)
        }

      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)

    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token
    
    const form = event.currentTarget
    uploadFile(form, authToken)

  }

  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }

  const getAllTopics = () => {
    topic.getAllTopics(`level=${selectedLevel?.value}`)
      .then(resp => {
        const data = groupTopicsByUnit(resp.results)

        setListedSubjects([
          {
            label: "Learning Areaes",
            options: data.map(item => ({
              label: item?.unit,
              value: item?.unit,
              topics: item?.topics
            }))
          }])

      }).catch(err => {

      })
  }
  const getAllSubTopics = () => {
    subtopic.getAllSubTopics(`topic=${selectedTopic?.value}`)
      .then(resp => {
        const data = resp.subTopics
        setSubTopics([
          {
            label: "Sub Strands ",
            options: data.map(item => ({
              label: item?.subTopicName,
              value: item?._id,
            }))
          }])

      }).catch(err => {

      })
  }

  useEffect(() => {
    props.selected?.fileType === "pdf" ? setSelectedFileType({
      label: "Pdf",
      value: "pdf",
    }) : props.selected?.fileType === "pptx" ? setSelectedFileType({
      label: "Power Point",
      value: "pptx",
    }) : props.selected?.fileType === "audio" ? setSelectedFileType({
      label: "Audio",
      value: "audio",
    }) : props.selected?.fileType === "video  " ? setSelectedFileType({
      label: "Video",
      value: "video",
    }) : null
  }, [props.selected])


  useEffect(() => {
    setLevelTypes([
      {
        label: "Level Tpyes",
        options: props.levelTypes.map(item => ({
          label: item?.levelTypeName,
          value: item?.levelTypeName,
          levels: item?.levels
        }))
      }
    ])
  }, [props.levelTypes])

  useEffect(() => {
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])

  useEffect(() => {
    setTopics([
      {
        label: "Strands ",
        options: selectedSubject?.topics.map(item => ({
          label: item?.topicName,
          value: item?.topicName,
        }))
      }
    ])
  }, [selectedSubject])

  useEffect(() => {
    if (selectedLevel) {
      getAllTopics()
    }
  }, [selectedLevel])

  useEffect(() => {
    if (selectedTopic) { getAllSubTopics() }
  }, [selectedTopic])


  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Content
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3" controlId="levelType">
              <Form.Label>
                Level Type
              </Form.Label>
              <Select
                value={selectedLevelType}
                onChange={handleSelectLevelType}
                options={levelTypes}
              />
            </Form.Group>
            {selectedLevelType && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Level
                </Form.Label>
                <Select
                  value={selectedLevel}
                  onChange={handleSelectLevel}
                  options={levels}
                />
              </Form.Group>
            )}
            {selectedLevel && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Learning Areas
                </Form.Label>
                <Select
                  value={selectedSubject}
                  onChange={handleSelectSubject}
                  options={listtedSubject}
                />
              </Form.Group>
            )}
            {selectedSubject && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Strands
                </Form.Label>
                <Select
                  value={selectedTopic}
                  onChange={handleSelectTopic}
                  options={topics}
                />
              </Form.Group>
            )}
            {selectedTopic && (<Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sub Strands
              </Form.Label>
              <Select
                value={selectedSubTopic}
                onChange={handleSelectSubTopic}
                options={subTopics}
              />
            </Form.Group>)}

            {props.toEdit === false && (
              <Form.Group className="mb-3">
                <Form.Label>
                  Select File Type
                </Form.Label>
                <div className="d-flex" >
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value })
                      }}
                      type="radio"
                      defaultChecked
                      name="flexRadioDisabled"
                      value={"pdf"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Pdf
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value })
                      }}
                      type="radio"
                      name="flexRadioDisabled"
                      value={"pptx"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Power Point
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value })
                      }}
                      value={"audio"}
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Audio
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value })
                      }}
                      type="radio"
                      value={"video"}
                      name="flexRadioDisabled" id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Video
                    </label>
                  </div>
                </div>
              </Form.Group>
            )}
            {props.toEdit === false && (
              <Form.Group className="mb-3" controlId="">
                <Form.Label>
                  Upload File
                </Form.Label>
                <div className="d-flex align-items-center">
                  <input
                    name="file"
                    id="file"
                    className="form-control"
                    type="file"
                    accept={selectedFileType.value === "audio" ? '.mp3' : selectedFileType.value === "pdf" ? '.pdf' : selectedFileType.value === "video" ? '.mp4' : null}

                    onChange={handleFileChange}
                    required
                  />
                </div>
              </Form.Group>
            )}

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}

              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditContent.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  toEdit: PropTypes.bool,
  setToEdit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func
}


export default AddEditContent