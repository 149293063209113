import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


export const addLeveL = async (payload, token) => {
    return await axios
      .post(getApi.SETUP.ADD_LEVEL, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err))
  }
  

  export const getAllLevels = async (params) => {
    return await axios
      .get(`${getApi.SETUP.GET_ALL_LEVELS}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const getLevelById = async (id) => {
    return await axios
      .get(`${getApi.SETUP.GET__LEVEL_BY_ID}/${id}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  
  export const updateLevel = async (payload, token, levelId) => {
    return await axios
      .patch(`${getApi.SETUP.UPDATE_LEVEL}/${levelId}`, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };

  export const deleteLeveL = async (token, levelId) => {
    return await axios
      .delete(`${getApi.SETUP.DELETE_LEVEL}/${levelId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data
      })
      .catch(err => catchError("", err))
  }