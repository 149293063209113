import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select"


import * as topic from "models/setup/topics";
import * as subtopic from "models/setup/subtopics";
import * as test from "models/contents/tests";

const CreateExamModal = props => {
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [listtedSubject, setListedSubjects] = useState([])
  const [topics, setTopics] = useState([])
  const [subTopics, setSubTopics] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedSubTopic, setSelectedSubTopic] = useState(null)

  const sessions = [{
    label: "Sessions",
    options: [
      { label: "Term 1", value: "Term 1" },
      { label: "Term 2", value: "Term 2" },
      { label: "Term 3", value: "Term 3" }
    ]
  }
  ]

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.setToEdit(false)
    props.setSelected(null)
    window.location.href = '/content-management/quizes-and-tests';
  }


  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSelectSession = (selectedSession) => {
    setSelectedSession(selectedSession)
  }

  const handleSelectSubject = (selectedSubject) => {
    setSelectedSubject(selectedSubject)
  }
  const handleSelectTopic = (selectedTopic) => {
    setSelectedTopic(selectedTopic)
  }
  const handleSelectSubTopic = (selectedSubTopic) => {
    setSelectedSubTopic(selectedSubTopic)
  }
  const handleTest = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      testName: form.testName.value,
      subTopic: selectedLevelType?.value,
      levelType: selectedLevelType?.value,
      level: selectedLevel?.value,
      sessionName: selectedSession?.value,
      questions: props.questions

    }
    if (props.toEdit) {
      test.updateTest(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      test.addTest(payload, token,)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  const handleQuiz = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      quizName: form.quizName.value,
      levelType: selectedLevelType?.value,
      level: selectedLevel?.value,
      sessionName: selectedSession?.value,
      subTopic: selectedSubTopic?.value,
      questions: props.questions

    }
    if (props.toEdit) {
      test.updateQuiz(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      test.addQuiz(payload, token,)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }

  const getAllTopics = () => {
    topic.getAllTopics(`level=${selectedLevel?.value}`)
      .then(resp => {
        const data = groupTopicsByUnit(resp.topics)

        setListedSubjects([
          {
            label: "Learning Areaes",
            options: data.map(item => ({
              label: item?.unit,
              value: item?.unit,
              topics: item?.topics
            }))
          }])

      }).catch(err => {

      })
  }
  const getAllSubTopics = () => {
    subtopic.getAllSubTopics(`topic=${selectedTopic?.value}`)
      .then(resp => {
        const data = resp.subTopics
        setSubTopics([
          {
            label: "Sub Strands ",
            options: data.map(item => ({
              label: item?.subTopicName,
              value: item?.subTopicName,
            }))
          }])

      }).catch(err => {

      })
  }

  useEffect(() => {
    if (selectedTopic) { getAllSubTopics() }
  }, [selectedTopic])

  useEffect(() => {
    setTopics([
      {
        label: "Strands ",
        options: selectedSubject?.topics.map(item => ({
          label: item?.topicName,
          value: item?.topicName,
        }))
      }
    ])
  }, [selectedSubject])
  useEffect(() => {
    setLevelTypes([
      {
        label: "Level Tpyes",
        options: props.levelTypes.map(item => ({
          label: item?.levelTypeName,
          value: item?.levelTypeName,
          levels: item?.levels
        }))
      }
    ])
  }, [props.levelTypes])

  useEffect(() => {
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])


  useEffect(() => {
    if (selectedLevel) {
      getAllTopics()
    }
  }, [selectedLevel])
  return (
    <React.Fragment>
      <Modal
        show={props.showModal}
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} {props.type}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            if (props.type === "quiz") {
              handleQuiz(e);
            } else if (props.type === "test") {
              handleTest(e);
            }
          }}>
            {props.type === "quiz" && (<Form.Group className="mb-3" controlId="quizName">
              <Form.Label>
                Quiz Name
              </Form.Label>
              <Form.Control
                placeholder="Enter Quiz Namae"
                required

              />
            </Form.Group>)}
            {props.type === "test" && (<Form.Group className="mb-3" controlId="testName">
              <Form.Label>
                Test Name
              </Form.Label>
              <Form.Control
                placeholder="Enter Sub Strand"
                required

              />
            </Form.Group>)}

            <Form.Group className="mb-3" controlId="levelType">
              <Form.Label>
                Level Type
              </Form.Label>
              <Select
                value={selectedLevelType}
                onChange={handleSelectLevelType}
                options={levelTypes}
              />
            </Form.Group>

            {selectedLevelType && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Level
                </Form.Label>
                <Select
                  value={selectedLevel}
                  onChange={handleSelectLevel}
                  options={levels}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sessions
              </Form.Label>
              <Select
                value={selectedSession}
                onChange={handleSelectSession}
                options={sessions}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Learning Areas
              </Form.Label>
              <Select
                value={selectedSubject}
                onChange={handleSelectSubject}
                options={listtedSubject}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Strands
              </Form.Label>
              <Select
                value={selectedTopic}
                onChange={handleSelectTopic}
                options={topics}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sub Strands
              </Form.Label>
              <Select
                value={selectedSubTopic}
                onChange={handleSelectSubTopic}
                options={subTopics}
              />
            </Form.Group>
            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment >
  )
}

CreateExamModal.propTypes = {}

export default CreateExamModal