import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import Profile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import UserManagement from "pages/UserManagement"
import ContentManagement from "pages/ContentManagement"
import UserProfile from "pages/UserManagement/UserProfile"
import Levels from "pages/Setup/Levels"
import PackageAndSubscription from "pages/Setup/Package"
import Contents from "pages/ContentManagement/Contents/index.js"
import ExamsAndTests from "pages/ContentManagement/ExamsAndTests"
import Units from "pages/Setup/Units"
import Topics from "pages/Setup/Topics"
import Analytics from "pages/Analytics"
import Logs from "pages/Logs"
import SubTopics from "pages/Setup/SubTopics"
import ExamInterface from "pages/ContentManagement/ExamsAndTests/ViewExam"
import CreateQuestionInterface from "pages/ContentManagement/ExamsAndTests/CreateTestAndExams"
import Lectures from "pages/ContentManagement/Lectures"
import Practicals from "pages/ContentManagement/Pracicals"
import Roles from "pages/Setup/Roles"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/user-management", component: UserManagement },

  { path: "/content-management/contents", component: Contents },
  { path: "/content-management/quizes-and-tests", component: ExamsAndTests },
  { path: "/content-management/quizes-and-tests/:type", component: CreateQuestionInterface },
  { path: "/content-management/quizes-and-tests/view/:type/:id", component: ExamInterface },
  { path: "/content-management/lectures", component: Lectures },
  { path: "/content-management/practicals", component: Practicals },

  { path: "/setup/learning-areas", component: Units },
  { path: "/setup/learning-area/:unitName", component: Topics },
  { path: "/setup/learning-area/:unitName/:topicName", component: SubTopics },
  { path: "/setup/levels", component: Levels },
  { path: "/setup/package-and-subscription", component: PackageAndSubscription },
  { path: "/setup/roles-and-permissions", component: Roles },

  { path: "/analytics", component: Analytics },

  { path: "/logs", component: Logs },

  // //profile
  { path: "/profile", component: Profile },
  { path: "/user-profile/:id", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  // { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
