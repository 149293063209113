// ExpandableRowComponent.jsx
import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner } from "react-bootstrap";
import moment from "moment"

// Toast Alert
import toast from "react-hot-toast"
import TableTemplate from "components/TableTemplate"

import * as level from "models/setup/levels";

import AddEditLevel from "./AddEditLevel";
import DeleteLevel from "./DeleteLevel";

const ExpandableRowComponent = (props) => {
  const [levels, setLevels] = useState([])


  const [IsLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [validated, setValidated] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditLevel, setShowAddEditLevel] = useState(false)
  const [showDeleteLevel, setShowDeleteLevel] = useState(false)

  const rowData = props.data; // Accessing data from the parent

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditLevel(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteLevel(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "LeveL ", selector: row => row?.levelName },
    { name: "LeveL Type", selector: row => row?.levelTypeName },

    {
      name: "Status",
      selector: row =>
        row?.levelTypeStatus === "active" ? "Active" :
          row?.levelTypeStatus === "inactive" ? "Inactive" :
            row?.levelTypeStatus === "suspended" ? "Suspended" : null,

      conditionalCellStyles: [
        {
          when: (row) => row?.levelTypeStatus === 'active',
          style: {
            color: 'green',
          },
        },
        {
          when: (row) => row?.levelTypeStatus === 'inactive',
          style: {
            color: 'orange',
          },
        },
        {
          when: (row) => row?.levelTypeStatus === 'suspended',
          style: {
            color: 'danger',
          },
        },
      ]
    },

    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex justify-content-end ">

      <button className="btn btn-primary"
        onClick={() => setShowAddEditLevel(true)}>
        Add Level
      </button>
    </div>
  )

  const getAllLevel = () => {
    setIsLoaded(false)
    level.getAllLevels(`levelType=${rowData?.levelTypeName}`)
      .then(resp => {
        const data = resp.results
        setLevels(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllLevel()
  }, [rowData])

  return (
    <div className="bg-primary bg-soft p-2">
      <div className="bg-white p-2 ">
        <TableTemplate
          columns={columns}
          rows={levels}
          dataLoaded={isLoaded}
          subHeaderNav={subHeaderNav}
        />
        <AddEditLevel
          showModal={showAddEditLevel}
          setShowModal={setShowAddEditLevel}
          toEdit={toEdit}
          setToEdit={setToEdit}
          selected={selected}
          setSelected={setSelected}
          fetchData={getAllLevel}
          levelType={rowData?.levelTypeName}
          levelTypeId={rowData?._id} />
        <DeleteLevel
          showModal={showDeleteLevel}
          setShowModal={setShowDeleteLevel}
          selected={selected}
          setSelected={setSelected} />
      </div>
    </div>
  );
};

export default ExpandableRowComponent;
