import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Container } from "react-bootstrap"
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import moment from "moment"
import TableTemplate from "components/TableTemplate"

import * as unit from "models/setup/units";

import AddEditUnit from "./AddEditUnit";
import DeleteUnit from "./DeleteUnit";

const Units = props => {
  const [units, setUnits] = useState([])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditUnit, setShowAddEditUnit] = useState(false)
  const [showDeleteUnit, setShowDeleteUnit] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/setup/learning-area/${row?.unitName}`}>
              View
            </Link>
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditUnit(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteUnit(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Name", selector: row => row?.unitName },

    // {
    //   name: "Status",
    //   selector: row =>
    //     row?.levelTypeStatus === "active" ? "Active" :
    //       row?.levelTypeStatus === "inactive" ? "Inactive" :
    //         row?.levelTypeStatus === "suspended" ? "Suspended" : null,

    //   conditionalCellStyles: [
    //     {
    //       when: (row) => row?.levelTypeStatus === 'active',
    //       style: {
    //         color: 'green',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'inactive',
    //       style: {
    //         color: 'orange',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'suspended',
    //       style: {
    //         color: 'danger',
    //       },
    //     },
    //   ]
    // },
    { name: "Updated", selector: row => moment(row.updatedAt).format('LL'), },
    { name: "Created", selector: row => moment(row.createdAt).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex">

      <button className="btn btn-primary"
        onClick={() => setShowAddEditUnit(true)}>
        Add Learning Area
      </button>
    </div>
  )

  const getAllUnits = (params) => {
    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token
    
    unit.getAllUnits(params, authToken)
      .then(resp => {
        const data = resp.results
        setUnits(data)
        setIsLoaded(true)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllUnits()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Card.Header >
            <Card.Title className="text-primary">Learnig Areas</Card.Title>
          </Card.Header>
          <Card.Body>
            <TableTemplate
              columns={columns}
              rows={units}
              dataLoaded={isLoaded}
              subHeaderNav={subHeaderNav}
            // expandableRows={true}
            // expandableRowsComponent={ExpandableRowComponent}
            />

            <AddEditUnit
              showModal={showAddEditUnit}
              setShowModal={setShowAddEditUnit}
              toEdit={toEdit}
              setToEdit={setToEdit}
              selected={selected}
              setSelected={setSelected}
              fetchData={getAllUnits}
            />
            <DeleteUnit
              showModal={showDeleteUnit}
              setShowModal={setShowDeleteUnit}
              selected={selected}
              setSelected={setSelected}
              fetchData={getAllUnits}
            />
          </Card.Body>
        </Card>
      </Container>

    </div>
  )
}

Units.propTypes = {}

export default Units