import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


export const addLeveLType = async (payload, token) => {
    return await axios
      .post(getApi.SETUP.ADD_LEVELTYPE, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err))
  }
  
  
  export const getAllLevelTypes = async (params) => {
    return await axios
      .get(`${getApi.SETUP.GET_ALL_LEVELTYPES}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const getLevelTypeById = async (id) => {
    return await axios
      .get(`${getApi.SETUP.GET__LEVELTYPES_BY_ID}/${id}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  

  export const updateLevelType = async (payload, token, levelTypeId) => {
    return await axios
      .patch(`${getApi.SETUP.UPDATE_LEVELTYPE}/${levelTypeId}`, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
 
  export const deleteLeveLType = async (token, levelTypeId,) => {
    return await axios
      .delete(`${getApi.SETUP.DELETE_LEVELTYPE}/${levelTypeId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data
      })
      .catch(err => catchError("", err))
  }