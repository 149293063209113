
// main API URL
let baseUrl = "https://tehuti-learning-hub-api.onrender.com"
//"https://tehuti-learning-hub-api.onrender.com"
//"http://localhost:3009"
//"https://api.iq.steticduka.co.ke";
// "http://localhost:3001"
//"http://localhost:3009"
//"https://api.steticduka.co.ke";

export const getApi = {
  ROLES:{
    GET_ROLES: `${baseUrl}/roles`,
    GET_PERMISSIONS: `${baseUrl}/roles/permissions`,
    GET_ROLE_BY_ID:`${baseUrl}/roles`,
    ADD_ROLE: `${baseUrl}/roles`,
    UPDATE_ROLE: `${baseUrl}/roles`,
    DELETE_ROLE:`${baseUrl}/roles`
  },
  AUTH: {
    LOGIN: `${baseUrl}/auth/login`,
    REGISTER: `${baseUrl}/auth/register`,
    REGISTER_AS_ADMIN: `${baseUrl}/auth/registerByAdmin`,
    RESET_PASSWORD: `${baseUrl}/auth/reset-password`,
    GET_PROFILE: `${baseUrl}/auth/profile`,
},
USERS:{
  CREATE_USER: `${baseUrl}/users`,
  GET_ALL_USERS: `${baseUrl}/users`,
  GET_USER_BY_ID: `${baseUrl}/users`,
  UPDATE_USER: `${baseUrl}/users`,
  UPDATE_PASSWORD: `${baseUrl}/users`,
  ACTIVATE_USER: `${baseUrl}/users`,
  DEACTIVATE_USER: `${baseUrl}/users`,
  DELETE_USER: `${baseUrl}/users`,
  FORGET_PASSWORD: `${baseUrl}/users`,
  SET_PASSWORD: `${baseUrl}/users`
},
  ACCOUNTS: {
    VERIFY_OTP: `${baseUrl}/auth/verify-otp`,
    SEND_OTP: `${baseUrl}/auth/send-otp`,
    REGISTER: `${baseUrl}/users/register`,
    REGISTER_AS_ADMIN: `${baseUrl}/users/registerByAdmin`,
    GET_ALL_USERS: `${baseUrl}/users`,
    GET_USER_BY_ID: `${baseUrl}/users`,
    UPDATE_USER: `${baseUrl}/users`,
    UPDATE_PASSWORD: () => `${baseUrl}/users/updatePassword/${id}`,
    ACTIVATE_USER: `${baseUrl}/users/activate`,
    DEACTIVATE_USER: `${baseUrl}/users/de-activate`,
    DELETE_USER: (id) => `${baseUrl}/users/deleteUser/${id}`,
    FORGET_PASSWORD: `${baseUrl}/users/forgetPassword`,
    SET_PASSWORD: `${baseUrl}/users/setNewPassword`,

  },

  LOGS: {
    // items
    GET_ALL_LOGS: `${baseUrl}/logs/getAllLogs`,
    GET_LOG_BY_ID: `${baseUrl}/logs/getLogById`,



  },

  SETUP: {
    //levelTypes
    GET_ALL_LEVELTYPES: `${baseUrl}/level-types`,
    GET__LEVELTYPES_BY_ID: `${baseUrl}/level-types`,
    GET_LEVELTYPES_BY_NAME: `${baseUrl}/level-types`,
    //POST
    ADD_LEVELTYPE: `${baseUrl}/level-types`,
    //PUT
    UPDATE_LEVELTYPE: `${baseUrl}/level-types`,
    //DELETE
    DELETE_LEVELTYPE: `${baseUrl}/level-types`,

    //level
    GET_ALL_LEVELS: `${baseUrl}/levels`,
    GET__LEVEL_BY_ID: `${baseUrl}/levels`,
    GET_LEVEL_BY_NAME: `${baseUrl}/levels`,
    //POST
    ADD_LEVEL: `${baseUrl}/levels`,
    //PUT
    UPDATE_LEVEL: `${baseUrl}/levels`,
    //DELETE
    DELETE_LEVEL: `${baseUrl}/levels`,

    //units
    GET_ALL_UNITS: `${baseUrl}/units`,
    GET__UNIT_BY_ID: `${baseUrl}/units`,
    //POST
    ADD_UNIT: `${baseUrl}/units`,
    ADD_TOPIC: `${baseUrl}/units`,
    //PUT
    UPDATE_UNIT: `${baseUrl}/units`,
    //DELETE
    DELETE_UNIT: `${baseUrl}/units`,


    //topics
    GET_ALL_TOPICS: `${baseUrl}/topics`,
    //POST
    ADD_TOPIC: `${baseUrl}/topics`,
    //PUT
    UPDATE_TOPIC: `${baseUrl}/topics`,
    //DELETE
    DELETE_TOPIC: `${baseUrl}/topics`,


    //subTopics
    GET_ALL_SUB_TOPICS: `${baseUrl}/sub-topics`,
    GET__SUB_TOPIC_BY_ID: `${baseUrl}/sub-topics`,
    //POST
    ADD_SUB_TOPIC: `${baseUrl}/sub-topics`,
    //PUT
    UPDATE_SUB_TOPIC: `${baseUrl}/sub-topics`,
    //DELETE
    DELETE_SUB_TOPIC: `${baseUrl}/sub-topics`,

    

    //quizes
    GET_ALL_QUIZES: `${baseUrl}/tests`,
    GET_QUIZ_BY_ID: `${baseUrl}/tests`,
    //POST
    ADD_QUIZ: `${baseUrl}/tests`,
    //PUT
    UPDATE_QUIZ: `${baseUrl}/tests`,
    //DELETE
    DELETE_QUIZ: `${baseUrl}/tests`,

    //Tests
    GET_ALL_TESTS: `${baseUrl}/tests`,
    GET_TEST_BY_ID: `${baseUrl}/tests`,
    //POST
    ADD_TEST: `${baseUrl}/tests`,
    //PUT
    UPDATE_TEST: `${baseUrl}/tests`,
    //DELETE
    DELETE_TEST: `${baseUrl}/tests`,

  },

  LECTURES: {
    //Tests
    GET_ALL_LECTURES: `${baseUrl}/lectures`,
    GET_LECTURE_BY_ID: `${baseUrl}/lectures`,
    //POST
    ADD_LECTURE: `${baseUrl}/lectures`,
    JOIN_LECTURE: `${baseUrl}/lectures`,
    //PUT
    UPDATE_LECTURE: `${baseUrl}/lectures`,
    //DELETE
    DELETE_LECTURE: `${baseUrl}/lectures`,
  },
  ANALYTICS: {
    //category
    GET_ANALYTICS: `${baseUrl}/analytics`,


  }

};
