import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Post Funtions
export const register = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.REGISTER, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const registerAsAdmin = async (payload, token) => {
  return await axios
    .post(getApi.ACCOUNTS.REGISTER_AS_ADMIN, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const sendOtp = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.SEND_OTP, payload)
    .then(async ({ data }) => {

      return data;
    })
    .catch(err => catchError(err, err));
};

export const verifyOtp = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.VERIFY_OTP, payload)
    .then(async ({ data }) => {

      return data;
    })
    .catch(err => catchError(err, err));
};

//get Funtions
export const getUserById = async (id, token) => {
  return await axios
    .get(`${getApi.ACCOUNTS.GET_USER_BY_ID}/${id}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getAllUsers = async (params, token) => {
  return await axios
    .get(`${getApi.ACCOUNTS.GET_ALL_USERS}?${params === undefined ? "" : params}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getUserProfile = async (params, token) => {
  return await axios
    .get(`${getApi.AUTH.GET_PROFILE}?${params === undefined ? "" : params}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//put Functions

export const updateProfile = async (userId, payload, token) => {
  return await axios
    .put(`${getApi.ACCOUNTS.UPDATE_USER}/${userId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const updatePassword = async (userId, payload, token) => {
  return await axios
    .put(`${getApi.ACCOUNTS.UPDATE_PASSWORD(userId)}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const activateUser = async (userId) => {
  return await axios
    .put(`${getApi.ACCOUNTS.ACTIVATE_USER}/${userId}`,)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//Delete Funtions

export const deleteUser = async (userId, token) => {
  return await axios
    .delete(`${getApi.ACCOUNTS.DELETE_USER(userId)}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}

export const deactivateUser = async (userId) => {
  return await axios
    .put(`${getApi.ACCOUNTS.DEACTIVATE_USER}/${userId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};



export const getLoggedInUserData = () => {
  return JSON.parse(localStorage.getItem("authCoCAdmin"));
};

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem("authCoCAdmin"))?.token;
};



export const forgotPassword = async (payload,) => {
  return await axios
    .post(`${getApi.ACCOUNTS.FORGET_PASSWORD}`, payload, {

    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const setPassword = async (payload) => {
  return await axios
    .post(`${getApi.ACCOUNTS.SET_PASSWORD}`, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

