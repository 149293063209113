import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

export const uploadContent = async (params) => {
  return await axios
    .post(`${getApi.SETUP.GET_ANALYTICS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};


export const deleteContent = async (params) => {
    return await axios
      .delete(`${getApi.SETUP.GET_ANALYTICS}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  