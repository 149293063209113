import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner, Col, Card } from "react-bootstrap"
import toast from "react-hot-toast";

const ScoreCard = props => {
  const tabs = [
    { title: "Total Students", value: "0" }, { title: "Active", value: "0" }, { title: "Inactive", value: "0" }, { title: "Suspended", value: "0" },
  ]
  return (
    <div className="d-flex justify-content-between flex-wrap">
      {tabs.map((item, index) => (
        <Col key={index} className="p-1">
          <Card>
            <Card.Body>
              <div>
                {item?.title}
              </div>
              <div>
                {item?.value}
              </div></Card.Body>
          </Card>
        </Col>
      ))}
    </div>
  )
}
ScoreCard.propTypes = {}

export default ScoreCard