import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Link, withRouter } from "react-router-dom"

import { Form, InputGroup, Spinner } from "react-bootstrap"

// Toast Alert
import toast from "react-hot-toast"

// Importing Custom Components
import Logo from "./../../assets/images/Tehuti-Logo.png"

import AuthBody from "./AuthBody"

import * as auth from "models/auth"

const Login = props => {
  const [remember, setRemember] = useState(false)
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [timer, setTimer] = useState(60); // Timer in seconds
  const [canResend, setCanResend] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [inputType, setInputType] = useState("email");

  // Processing Globals
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPword, setShowPword] = useState(false)

  const handleResendOtp = () => {
    setCanResend(false); // Disable resend
    setTimer(60); // Start a new 60-second timer

    sendOtp(null, {
      ...(inputType === "email" ? { emailAddress: email } : {}),
      ...(inputType === "phone" ? { phoneNumber: phoneNumber } : {}),
    });
  };

  const sendOtp = (event, customPayload) => {
    setIsLoading(true);

    let payload;

    if (event) {
      // Handling when called by form submission
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      payload = {
        ...(inputType === "email" ? { emailAddress: form.userEmail.value } : {}),
        ...(inputType === "phone" ? { phoneNumber: form.phoneNumber.value } : {}),
      };
    } else if (customPayload) {
      // Handling when called directly
      payload = customPayload;
    } else {
      setIsLoading(false);
      toast.error("Invalid request");
      return;
    }

    auth
      .sendOtp(payload)
      .then((resp) => {
        toast.success(resp?.message);
        setOtpSent(true);
        if (inputType === "email") setEmail(payload.emailAddress);
        if (inputType === "phone") setPhoneNumber(payload.phoneNumber);
        setIsLoading(false);
        setTimer(60);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const verifyOtp = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      ...(inputType === 'email'
        ? { emailAddress: form.userEmail.value, }
        : {}),
      ...(inputType === 'phone'
        ? { phoneNumber: form.phoneNumber.value, }
        : {}),

      otp: form.otp.value,
    }
    auth.verifyOtp(payload)
      .then(resp => {
        const data = resp
        setIsLoading(false)
        const date = Date.now()
        
          // data?.userType === "student" ? (toast.error("You are not allowed to use this portal")) : 
          setIsLoading(false)
          localStorage.setItem("userAdmin", JSON.stringify(data?.user)),
          localStorage.setItem("Token", JSON.stringify(data?.tokens)),
            props.history.push("/dashboard")
        

      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true); // Allow resend once the timer hits 0
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount or timer change
  }, [timer]);

  const mainForm = (
    <div className=" mx-3 mx-md-5">
      <div className="w-100 bg-white">
        <div className="d-flex flex-column h-100 mx-4">
          <div className=" start-brand-box">
            <div className="logo logo-dark mt-2  ">
              <span className="logo-sm">
                <img src={Logo} alt="" height="40px" />
              </span>
              <span className="logo-lg">
                <img src={Logo} alt="" height="60px" className="image-fluid" />
              </span>
            </div>
          </div>

          <div className="my-4">
            {!otpSent ? (<Form onSubmit={sendOtp} className="">
              {/* Toggle to select Email or Phone */}
              <Form.Group className="mb-3" controlId="inputType">
                <Form.Label>Choose Sign in option.</Form.Label>
                <div className="d-flex gap-3">
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => { setInputType(e.target.value), console.log(inputType) }}
                      type="radio"
                      defaultChecked
                      name="flexRadioDisabled"
                      value={"email"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Email
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => { setInputType(e.target.value), console.log(inputType) }}
                      type="radio"
                      name="flexRadioDisabled"
                      value={"phone"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Phone Number
                    </label>
                  </div>
                </div>
              </Form.Group>

              {/* Email Input */}
              {inputType === "email" && (
                <Form.Group className="mb-3" controlId="userEmail">
                  <Form.Label>User Email</Form.Label>
                  <InputGroup className="form-control-lgm">
                    <Form.Control
                      type="email"
                      placeholder="e.g. johndoe@email.com"
                      defaultValue={email}
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Email
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {/* Phone Number Input */}
              {inputType === "phone" && (
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <InputGroup className="form-control-lgm">
                    <Form.Control
                      type="text"
                      placeholder="e.g. 0712345678"
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Phone Number
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <div className="mt-4 d-grid">
                <button
                  className="btn btn-primary btn-block btn-lg rounded-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              {/* <div className="d-flex justify-content-center mb-3 mt-2">
                <a
                  className="text-primary"
                  onClick={() => props.setShowSignUp(true)}
                >
                  Create an account.
                </a>
              </div> */}
            </Form>) : (<Form
              onSubmit={verifyOtp}
              className=""
            >
              {/* Email Input */}
              {inputType === "email" && (
                <Form.Group className="mb-3" controlId="userEmail">
                  <Form.Label>User Email</Form.Label>
                  <InputGroup className="form-control-lgm">
                    <Form.Control
                      type="email"
                      placeholder="e.g. johndoe@email.com"
                      value={email}
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Email
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {/* Phone Number Input */}
              {inputType === "phone" && (
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <InputGroup className="form-control-lgm">
                    <Form.Control
                      type="text"
                      value={phoneNumber}
                      placeholder="e.g. 0712345678"
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Phone Number
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group className="mb-3" controlId="otp">
                <Form.Label>OTP</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="Enter Otp"
                    required
                    className=""
                  />

                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Password
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <button
                  onClick={handleResendOtp}
                  disabled={!canResend}
                  className="btn btn-link text-primary"
                >
                  Resend OTP
                </button>
                {!canResend && (
                  <span className="text-muted small">
                    Resend available in {timer} seconds
                  </span>
                )}
              </div>

              <div className="mt-4 d-grid ">
                <button
                  className="btn btn-primary btn-block btn-lg rounded-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center mb-3 mt-2">
                <a className="text-primary"
                  onClick={() => props.setShowSignUp(true)}>
                  Create an account.
                </a>
              </div>
            </Form>)}



          </div>
        </div>
      </div>
    </div>
  )

  return (
    <AuthBody
      form={mainForm}
      meta="Login | Tehuti Admin"
    />
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
