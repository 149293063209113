import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


export const addUnit = async (payload, token) => {
    return await axios
      .post(getApi.SETUP.ADD_UNIT, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err))
  }
  export const uploadContent = async (formData, subTopicId, token) => {
    return await axios
      .post(`${getApi.SETUP.UPLOAD_CONTENT}/${subTopicId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
  
        onUploadProgress: (progressEvent) => {
          const percentComplete = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Upload progress: ${percentComplete}%`);
          // You can update your progress bar or UI here
        },
  
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const getAllContents = async (params) => {
    return await axios
      .get(`${getApi.SETUP.GET_ALL_CONTENTS}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const deleteContent = async (token, contentId) => {
    return await axios
      .delete(`${getApi.SETUP.DELETE_CONTENT}/${contentId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data
      })
      .catch(err => catchError("", err))
  }
  

  export const getAllUnits = async (params) => {
    return await axios
      .get(`${getApi.SETUP.GET_ALL_UNITS}?${params === undefined ? "" : params}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };
  
  export const getUnitById = async (id) => {
    return await axios
      .get(`${getApi.SETUP.GET__UNIT_BY_ID}/${id}`)
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };

  export const updateUnit = async (payload, token, unitId) => {
    return await axios
      .patch(`${getApi.SETUP.UPDATE_UNIT}/${unitId}`, payload, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data;
      })
      .catch(err => catchError(err, err));
  };

  export const deleteUnit = async (token, unitId) => {
    return await axios
      .delete(`${getApi.SETUP.DELETE_UNIT}/${unitId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        return data
      })
      .catch(err => catchError("", err))
  }
  