import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast"

import * as role from 'models/setup/roles'

const AddEditRole = props => {
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [collapsed, setCollapsed] = useState({}); // we'll initialize after permissions load

  // Fetch permission groups from the API
  const getPermissions = () => {
    const token = JSON.parse(localStorage.getItem('Token'));
    const authToken = token?.authToken?.token;

    role.getPermissions(authToken)
      .then(resp => {
        setPermissions(resp);
      })
      .catch(err => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  // Once permissions are loaded, initialize the collapsed state.
  useEffect(() => {
    if (Object.keys(permissions).length > 0) {
      setCollapsed(
        Object.keys(permissions).reduce((acc, group) => {
          acc[group] = true; // true means the group is collapsed
          return acc;
        }, {})
      );
    }
  }, [permissions]);

  // When editing, initialize selectedPermissions from the role's permissions.
  useEffect(() => {
    if (props.toEdit && props.selected && props.selected.permissions) {
      const initSelected = {};
      props.selected.permissions.forEach(perm => {
        initSelected[perm] = true;
      });
      setSelectedPermissions(initSelected);
    }
  }, [props.toEdit, props.selected]);

  // Toggle all permissions in a group
  const toggleGroup = (group) => {
    if (!permissions[group]) return;
    const allSelected = permissions[group].every(
      (perm) => selectedPermissions[perm]
    );
    const newSelections = { ...selectedPermissions };
    permissions[group].forEach((perm) => {
      newSelections[perm] = !allSelected;
    });
    setSelectedPermissions(newSelections);
  };

  // Toggle an individual permission
  const togglePermission = (permission) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [permission]: !prev[permission],
    }));
  };

  // Toggle the collapse state for a group
  const toggleCollapse = (group) => {
    setCollapsed((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token

    const form = e.currentTarget;
    const roleName = form.roleName.value;
    // Convert selectedPermissions mapping to an array of permissions
    const selectedPerms = Object.keys(selectedPermissions).filter(
      key => selectedPermissions[key]
    );
    
    // Build your role object. For editing, you may want to include the role _id.
    const payload = {
      name: roleName,
      value: roleName.toLowerCase(),
      permissions: selectedPerms,
    };
    
    if (props.toEdit) {
      role.updateRole(authToken,payload,props.selected?._id)
        .then(resp => {

          closeModal()
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.response?.data?.message);
        })
    } else {
      role.addRole(authToken,payload)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal()
        }).catch(err => {
          setIsLoading(false)
           toast.error(err?.response?.data?.message);
        })

    }
  };

  const closeModal = () => {
    props.setShowModal(false);
    setIsLoading(false);
    props.fetchData();
    props.setToEdit(false);
    props.setSelected(null);
    setSelectedPermissions({});
    setCollapsed({})
  };

  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Role
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="roleName">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                placeholder="e.g. Admin"
                defaultValue={props.selected?.name} // assuming name field holds the role name
                required
              />
            </Form.Group>
            
            <div className="container mt-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Select Permissions</h5>
                  {Object.entries(permissions).map(([group, perms]) => (
                    <div key={group} className="mb-3 border-bottom pb-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`group-${group}`}
                            checked={perms.every(
                              (perm) => selectedPermissions[perm]
                            )}
                            onChange={() => toggleGroup(group)}
                          />
                          <label className="form-check-label" htmlFor={`group-${group}`}>
                            {group}
                          </label>
                        </div>
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={() => toggleCollapse(group)}
                          aria-expanded={!collapsed[group]}
                          aria-controls={`collapse-${group}`}
                        >
                          {collapsed[group] ? "Show" : "Hide"}
                        </button>
                      </div>
                      {/* Collapsible list of individual permissions */}
                      <div
                        id={`collapse-${group}`}
                        className={`${collapsed[group] ? "d-none" : ""} ms-4 mt-2`}
                      >
                        {perms.map((permission) => (
                          <div key={permission} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={permission}
                              checked={selectedPermissions[permission] || false}
                              onChange={() => togglePermission(permission)}
                            />
                            <label className="form-check-label" htmlFor={permission}>
                              {permission}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <h6>Selected Permissions:</h6>
                  <pre>{JSON.stringify(selectedPermissions, null, 2)}</pre>
                </div>
              </div>
            </div>
            
            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

AddEditRole.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  toEdit: PropTypes.bool,
  setToEdit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func
};

export default AddEditRole;
