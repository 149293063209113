import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";


export const addQuiz = async ( token,payload) => {
  return await axios
    .post(getApi.SETUP.ADD_QUIZ, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllQuizes = async (token,params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_QUIZES}?type=quiz${params === undefined ? "" : params}`,{
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },})
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getQuizById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET_QUIZ_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//patch funtions
export const updateQuiz = async ( token,payload,quizId) => {
  return await axios
    .patch(`${getApi.SETUP.UPDATE_QUIZ}/${quizId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteQuiz = async (token,quizId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_QUIZ}/${quizId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//Test
//post functions
export const addTest = async ( token,payload) => {
  return await axios
    .post(getApi.SETUP.ADD_TEST, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllTests = async (token,params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_TESTS}?type=test${params === undefined ? "" : params}`,{
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },})
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getTestById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET_TEST_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//patch funtions
export const updateTest = async (token, payload, testId) => {
  return await axios
    .patch(`${getApi.SETUP.UPDATE_TEST}/${testId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteTests = async (token,testId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_TEST}/${testId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}