  import React, { useState, useEffect } from "react"
  import PropTypes from "prop-types"
  import ReactApexChart from "react-apexcharts"
  import { Col, Dropdown, Row } from "react-bootstrap"
  //import getChartColorsArray from "components/Common/ChartsDynamicColor"

  import { ComposableMap, Geographies, Geography } from "react-simple-maps"
  import { scaleQuantize } from "d3-scale"
  import { csv } from "d3-fetch"
  import feature from "./../../components/kenyan-counties.geojson"

  const Overview = ({ dataColors, departments }) => {
    //const stackedColumnChartColors = getChartColorsArray(dataColors)
    const [graduates, setGraduates] = useState([])
    const [users, setUsers] = useState([])

    const [loadUsers, setLoadUsers] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [male, setMale] = useState([])
    const [female, setFemale] = useState([])
    const [graphdataMale, setGraphDataMale] = useState({})
    const [graphdataFemale, setGraphDataFemale] = useState({})
    const [mapFemale, setMapFemale] = useState([])
    const [mapMale, setMapMale] = useState([])
    const [mapGraduate, setMapGraduate] = useState([])

    const [hoveredRegion, setHoveredRegion] = useState(null)
    const [clickedRegion, setClickedRegion] = useState(null)

    const handleMouseEnter = region => {
      setHoveredRegion(region)
      const filter = graduates.filter(item => item.county === region)
      setMapGraduate(filter)
      setMapMale(filter.filter(item => item.gender === "Male"))
      setMapFemale(filter.filter(item => item.gender === "Female"))
    }

    const handleMouseLeave = () => {
      setHoveredRegion(null)
      setMapGraduate([])
      setMapMale([])
      setMapFemale([])
    }

    const handleRegionClick = (region) => {
      setClickedRegion(region);
    };

    const series = [
      {
        name: "Male",
        data: [20, 50.12, 56, 15, 45, 65, 45, 45, 45, 46, 78, 45,]
      },
      {
        name: "Female",
        data: [40, 20, 45, 46, 78, 65, 89, 41, 23, 46, 95, 45,],
      },

    ]
    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "30%",
          // endingShape: "rounded"
        },
      },
      dataLabels: {
        enabled: !1,
      },
      xaxis: {
        show: true,
        categories: ["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12",],
        labels: {
          show: true,
        },
      },
      // colors: stackedColumnChartColors,
      legend: {
        position: "right",
        //offsetY: "0%",
      },

      responsive: [
        {
          breakpoint: 768,
          options: {
            plotOptions: {
              bar: {
                horizontal: !1,
              },
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      fill: {
        opacity: 1,
      },
    }
    const [data, setData] = useState([])

    useEffect(() => {
      // https://www.bls.gov/lau/
      csv("/unemployment-by-county-2017.csv").then(counties => {
        setData(counties)
      })
    }, [])

    // Grouping function
    const groupBy = (array, ...keys) => {
      return array.reduce((acc, obj) => {
        const property = keys.reduce((o, key) => {
          const value = obj[key]
          if (!o[value]) o[value] = []
          return o[value]
        }, acc)
        property.push(obj)
        return acc
      }, {})
    }



    return (
      <div className="bg-white my-3 rounded">
        <h5 className="p-3">Overview</h5>
        <Row className="  d-flex  flex-md-row">
          <Col className=" bg-white ">
            <p className="mx-3">
              Student Distribution Per County.
            </p>

            <ComposableMap
              projection={"geoAzimuthalEqualArea"}
              projectionConfig={{
                rotate: [0, 0, 0],
                center: [38, 0],
                scale: 2500,
              }}
              height={500}
              width={400}
              color="green"
            >
              <Geographies geography={feature}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    const countyName = geo.properties?.COUNTY;
                    const isClicked = clickedRegion === countyName;
                    const isHovered = hoveredRegion === countyName;
                    const fillColor = isClicked
                      ? "#FF5722" // Color for clicked region
                      : isHovered
                        ? "#FFB100" // Color for hovered region
                        : "#2D3194";
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={() => handleMouseEnter(countyName)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleRegionClick(countyName)}
                        style={{
                          default: {
                            fill: fillColor,
                            stroke: "#fff",
                            strokeWidth: 0.75,
                            outline: "none",
                          },
                          hover: {
                            fill: "#fff",
                            stroke: "#607D8B",
                            strokeWidth: 0.75,
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            {hoveredRegion && (
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "200px",
                  backgroundColor: "#FFB100",
                  padding: "10px",
                  borderRadius: "5px",
                }}

                className="text-white"
              >
                {/* Render the content of the popup */}
                <p className="m-0">{hoveredRegion} County</p>
                <p className="m-0"> Total Students:{mapGraduate.length}</p>
                <p className="m-0"> Female:{mapFemale.length}</p>
                <p className="m-0">Male:{mapMale.length}</p>
              </div>
            )}
          </Col>
          <Col md={7}>
            <p className="mx-3">
              {clickedRegion} Student Distribution by Gender across different levels
            </p>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="400"

            //className="apex-charts"
            />

            <div className="d-flex px-3 mt-5 pb-2"></div>
          </Col>
        </Row>
      </div>
    )
  }

  Overview.propTypes = {
    departments: PropTypes.array
  }

  export default Overview
