import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import toast from "react-hot-toast";

import TableTemplate from "components/TableTemplate"


import * as log from "models/logs";

const Logs = props => {
  const [logs, setLogs] = useState([])

  //processing states
  const [isLoaded, setIsLoaded] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/setup/learning-area/${row?.unitName}`}>
              View
            </Link>
          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
  ]

  const getAllLogs = (params) => {
    const profile = JSON.parse(localStorage.getItem('authUser'));
    const token = profile?.token;

    log.getAllLogs(params, token)
      .then(resp => {
        const data = resp?.logs
        console.log(data)
        setLogs(data)
        setIsLoaded(true)
      }).catch(err => {
        if (err?.response?.data?.message === "Unauthorized") {
          let timerInterval;
          Swal.fire({
            title: "Unauthorized!",
            html: "Redirecting you to Login in <b></b> seconds.",
            timer: 5000, // Timer in milliseconds
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                const secondsLeft = Math.ceil(Swal.getTimerLeft() / 1000); // Convert ms to seconds
                timer.textContent = `${secondsLeft}`;
              }, 100); // Update every 100ms for smooth updates
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              localStorage.removeItem('authUser');
              window.location.href = "/login";
            }
          });

        } else {
          toast.error(err?.response?.data?.message)
        }
      })
  }

  useEffect(() => {
    getAllLogs()
  }, [])
  return (
    <div className="page-content">
      <Card>
        <Card.Header>
          <Card.Title className="text-primary">
            Logs
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <TableTemplate
            columns={columns}
            rows={logs}
            dataLoaded={isLoaded}
          />
        </Card.Body>
      </Card>

    </div>
  )
}

Logs.propTypes = {}

export default Logs