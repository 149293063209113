// ExpandableRowComponent.jsx
import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner } from "react-bootstrap";
import moment from "moment"

// Toast Alert
import toast from "react-hot-toast"
import TableTemplate from "components/TableTemplate"
import * as role from "models/setup/roles";



const ExpandableRowComponent = (props) => {
  const [levels, setLevels] = useState([])


  const [IsLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [validated, setValidated] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditLevel, setShowAddEditLevel] = useState(false)
  const [showDeleteLevel, setShowDeleteLevel] = useState(false)

  const rowData = props.data; // Accessing data from the parent

  const columns = [
 
    { name: "Permissions ", selector: row => row },
  ]

  const subHeaderNav = (
    <div className="d-flex justify-content-end ">

      <button className="btn btn-primary"
        onClick={() => setShowAddEditLevel(true)}>
        Add Level
      </button>
    </div>
  )



  return (
    <div className="bg-primary bg-soft p-2">
      <div className="bg-white p-2 ">
        <TableTemplate
          columns={columns}
          rows={rowData?.permissions}
          dataLoaded={true}
          subHeaderNav={subHeaderNav}
        />
      </div>
    </div>
  );
};

export default ExpandableRowComponent;
