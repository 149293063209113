import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

export const addTopic = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_TOPIC, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}


export const getAllTopics = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_TOPICS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getTopicById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__TOPIC_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};


export const updateTopic = async (payload, token, topicId) => {
  return await axios
    .patch(`${getApi.SETUP.UPDATE_TOPIC}/${topicId}`, payload, {

      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const deleteTopic = async (token, topicId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_TOPIC}/${topicId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}